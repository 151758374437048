body {
    overflow-x: hidden;
    margin: 0;
    font-family: Nexa, sans-serif;
    background: white !important;
}

.carousel .slide {
    background: transparent !important;
}

.carousel .slider-wrapper, .carousel.carousel-slider {
    overflow: visible !important;
}

.ril__navButtonNext:focus {
    outline: none;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    background: #2F89FC;
}

.CalendarDay__selected_span, .CalendarDay__hovered_span, .CalendarDay__hovered_span:hover {
    background: #2f89fc7a;
    color: white;
}

.CalendarDay__selected_span:active, .CalendarDay__selected_span:hover {
    background: #2F89FC;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
    display: none;
}

.advertise-swiper-container {
    width: 100% !important;
    position: relative !important;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    width: 12px !important;
    height: 12px !important;
}